import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-direction: column;
	width: 100%;

	.tabs-container {
		min-height: 27em;
		position: relative;

		.tabs-wrapper {
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 2em;

			.tabs {
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;
				background: rgb(241, 241, 241);
				padding: 0.3125em 0.375em;
				border-radius: 0.625em;
				gap: 1em;
			}
		}

		&.tabs-container-short {
			min-height: 20em;
		}

		.swipe-info {
			display: flex;
			justify-content: center;
			padding-top: 0.5em;
			width: 100%;
			position: absolute;
			font-size: 0.9em;
			margin-top: 0.5em;
			text-align: center;
			opacity: 0;
			animation: fadeInfoIn 2s ease forwards;
			display: flex;
			justify-content: center;

			.swipe-icon {
			}

			@keyframes fadeInfoIn {
				0% {
					opacity: 0;
				}
				10% {
					opacity: 1;
					transform: translateX(0);
				}
				20% {
					opacity: 1;
					transform: translateX(-10px);
				}
				30% {
					opacity: 1;
					transform: translateX(0);
				}
				40% {
					opacity: 1;
					transform: translateX(10px);
				}
				50% {
					opacity: 1;
					transform: translateX(0);
				}
				60% {
					opacity: 1;
					transform: translateX(0);
				}
				70% {
					opacity: 1;
					transform: translateX(-10px);
				}
				80% {
					opacity: 1;
					transform: translateX(0);
				}
				90% {
					opacity: 1;
					transform: translateX(10px);
				}
				100% {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}

		> .nav {
			padding-top: 2em;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			overflow-x: auto;
			scrollbar-width: none;
			-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar {
				display: none;
			}

			&.styled-h-scrollbar {
				justify-content: center;
				position: relative;

				&::-webkit-scrollbar {
					height: 0em;
					opacity: 0;
				}
			}

			.tab {
				padding: 0 2em;
				cursor: pointer;
				color: #262626;
				position: relative;
				white-space: nowrap;
				font-size: 0.875em;

				&.active {
					background-color: #ba9449;
					color: #fff;
					font-weight: 700;
					border-radius: 0.5em;
					padding: 0.285em 1.43em;
				}

				@media (pointer: fine) {
					&.active:hover {
						color: #fff;
					}
					&:hover {
						color: #ba9449;
					}
				}
			}
		}

		//Variant: Gradient
		& .variant-gradient {
			&.main-nav {
				padding: 1em 0;
			}

			&.sub-nav {
				padding: 0;
			}

			&.nav {
				.tabs-wrapper {
					.tabs {
						background-color: transparent;

						.tab {
							border-radius: 0.625em;
							font-size: 1.22em;
							color: #b9944a;
							padding: 0.3em;
							box-shadow: 0px 1px 6px #00000036;
							border: 1px solid #b9944a;
							min-width: 138px;
							display: flex;
							justify-content: center;

							&.active {
								color: #ffffff;
								background: ${vars.backgroundButtonGradient};
								border: none;
								font-weight: 400;
							}
						}

						&.main {
							gap: 1.4em;
						}

						&.sub {
							gap: 1.4em;

							.tab {
								background: transparent;
								border: none;
								padding: 0;
								box-shadow: none;
								min-width: max-content;
								padding: 0 0 0.4em 0;

								&.active {
									color: ${vars.colorGold};
									font-weight: 700;
									border-radius: 0;
									border-bottom: 2px solid ${vars.colorGold};
								}
							}
						}
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.tabs-container {
			min-height: 0;

			> .nav {
				&.styled-h-scrollbar {
					justify-content: center;

					&.less-than-3 {
						justify-content: center;
					}
				}

				.tab {
					font-size: 120%;
				}
			}

			//Variant: Gradient
			& .variant-gradient {
				font-size: 95%;
			}
		}
	}

	@media all and (max-width: ${vars.tabletS}) {
		.tabs-container {
			min-height: 0;

			> .nav {
				//Variant: Secondary
				&.variant-secondary {
					box-shadow: initial;

					.tab {
						color: #69696a;

						&.active {
							color: #161616;
							font-weight: 700;

							&:after {
								width: 35%;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								background-color: #161616;
							}
						}
					}
				}
			}

			//Variant: Gradient
			& .variant-gradient {
				&.nav {
					&.main-nav {
						width: 100%;
						.tabs-wrapper {
							width: 100%;
						}
					}

					.tabs-wrapper {
						.tabs {
							.tab {
								min-width: 83px;
								font-size: 1.1em;
							}
						}
					}

					&.sub-nav {
						justify-content: flex-start;
						margin: 0 1.4em;
						padding: 0 0 1em 0;
						font-size: 1.05em;
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileM}) {
		.tabs-container {
			//Variant: Gradient
			& .variant-gradient {
				&.nav {
					.tabs-wrapper {
						.tabs {
							
							.tab {
								transition: transform 0.1s ease-in-out;
								box-shadow: none;
							}

							&.main {
								gap: 0.6em;
								.active {
									transform: scale(1.2);
									margin: 0 0.5em;
								}
							}
						}
					}
				}
			}
		}
	}
`;
