import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props, TabVariants } from './types';

const LayoutTabNavigation: FunctionComponent<Props> = ({
    navChildren,
    tabs,
    subTabs,
    tabsVariant,
    activeTabKey,
    activeSubTabKey,
    onTabChange,
    onSubTabChange,
    customClass,
}) => {
    const tabContainerRef = useRef(null);
    const tabRefs = useRef([]);
    const [showSwipeInfo, setShowSwipeInfo] = useState(false);

    const getCurrentTab = () => {
        let foundTab = Array.isArray(tabs) && tabs.length > 0 && tabs.find(tab => tab.key === activeTabKey);
        if (!foundTab && tabs?.length > 0) {
            foundTab = tabs[0];
        }

        return foundTab;
    };

    const getSubCurrentTab = () => {
        let foundTab = Array.isArray(subTabs) && subTabs.length > 0 && subTabs.find(tab => tab.key === activeSubTabKey);
        if (!foundTab && subTabs?.length > 0) {
            foundTab = subTabs[0];
        }

        return foundTab;
    };

    const scrollEvent = () => {
        const offSet: number = -10;
        if (tabRefs?.current[0]?.getBoundingClientRect().left >= offSet) {
            setShowSwipeInfo(true);
        } else {
            setShowSwipeInfo(false);
        }
    };

    useEffect(() => {
        const offSet: number = -10;
        const availableTabsWidth = tabRefs.current.map((ref) => ref?.getBoundingClientRect().width);
        const tabsWidth = availableTabsWidth.reduce((acc, width) => acc + width, 0);
        const widthTabsContainer = tabContainerRef?.current?.getBoundingClientRect().width;

        if (tabsWidth > widthTabsContainer && tabRefs?.current[0]?.getBoundingClientRect().left >= offSet) {
            setShowSwipeInfo(true);
        }
    }, [tabs]);

    const onChangeTab = (key: string) => {
        onTabChange(key);
    };

    const currentTab = getCurrentTab();
    const currentSubTab = getSubCurrentTab();
    const tabsMarkup = useMemo(() => {
        if (!Array.isArray(tabs)) return null;

        return (
            <>
                {tabsVariant === TabVariants.Gradient && (
                    <div className={classnames({
                        'nav': true,
                        'less-than-3': tabs.filter(tab => Boolean(tab.label)).length < 3,
                        'main-nav': tabsVariant === TabVariants.Gradient,
                    }, `variant-${tabsVariant}`)}
                    >
                        <div className="tabs-wrapper">
                            <div className="tabs main">
                                {tabs
                                    .filter(tab => Boolean(tab.label))
                                    .map(({ key, label }, index) => (
                                        <div
                                            className={classnames({
                                                tab: true,
                                                active: currentTab.key === key,
                                            })}
                                            ref={(ref) => (tabRefs.current[index] = ref)}
                                            key={key}
                                            onClick={() => onChangeTab(key)}
                                        >
                                            {label}
                                            <span className="active-border" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )}
                <div
                    onScroll={() => {
                        if (tabs.filter(tab => Boolean(tab.label)).length < 3) return;
                        scrollEvent();
                    }}
                    className={classnames({
                        'nav styled-h-scrollbar': true,
                        'less-than-3': tabs.filter(tab => Boolean(tab.label)).length < 3,
                        'sub-nav': tabsVariant === TabVariants.Gradient,
                    }, `variant-${tabsVariant}`)}
                >
                    <div className="tabs-wrapper">
                        {tabsVariant !== TabVariants.Gradient && (
                            <div className="tabs main">
                                {tabs
                                    .filter(tab => Boolean(tab.label))
                                    .map(({ key, label }, index) => (
                                        <div
                                            className={classnames({
                                                tab: true,
                                                active: currentTab.key === key,
                                            })}
                                            ref={(ref) => (tabRefs.current[index] = ref)}
                                            key={key}
                                            onClick={() => onChangeTab(key)}
                                        >
                                            {label}
                                            <span className="active-border" />
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                        {Array.isArray(subTabs) && (
                            <div className="tabs sub">
                                {subTabs
                                    .filter(tab => Boolean(tab.label))
                                    .map(({ key, label }) => (
                                        <div
                                            className={classnames({
                                                tab: true,
                                                active: currentSubTab.key === key,
                                            })}
                                            key={key}
                                            onClick={() => onSubTabChange(key)}
                                        >
                                            {label}
                                            <span className="active-border" />
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }, [tabs, currentTab, subTabs, currentSubTab]);

    return (
        <StyledComponent className="layout-page-tab-navigation">
            <div
                ref={tabContainerRef}
                className={classnames('tabs-container', customClass)}
            >
                {navChildren
                    ? navChildren
                    : (tabsMarkup)}
                {(tabsVariant !== TabVariants.Gradient && showSwipeInfo) && (
                    <div className="swipe-info">
                        <img
                            className="swipe-icon"
                            src="/theme/images/swipe-arrow.svg"
                            loading="lazy"
                            alt="swipe arrow"
                        />
                    </div>
                )}
                {currentTab?.children && (
                    <div
                        className={classnames(
                            'content',
                            `tab-${currentTab?.key}`,
                        )}
                    >
                        {currentTab.children}
                    </div>
                )}
            </div>
        </StyledComponent>
    );
};

export default LayoutTabNavigation;
