import { ReactNode } from 'react';

export enum TabVariants {
    Primary = 'primary',
    Secondary = 'secondary',
    Gradient = 'gradient',
}

export interface Props {
    navChildren?: ReactNode;
    activeTabKey?: string;
    onTabChange?: (tabKey: string) => void;
    onSubTabChange?: (tabKey: string) => void;
    tabs: Tab[];
    subTabs?: SubTab[];
    activeSubTabKey?: string;
    tabsVariant?: TabVariants;
    customClass?: string;
}

export interface Tab {
    key?: string;
    label?: string;
    children: ReactNode;
}

export interface SubTab {
    key: string;
    label: string;
}
